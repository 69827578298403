import { EventEmitter } from 'events';
import * as request from './utils/request';
import triggers, { getParentByDataAttribute } from './triggers';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IntegrationConfig } from './interfaces';

declare global {
  interface ClientConfig {
    aiKey: string;
    dbmUrl: string;
    dbmNewUrl: string;
    cdnEditorDomain: string;
    configCatKey: string;
    editorDomain: string;
  }

  interface Window {
    oc: any;
    ENV_DATA: Readonly<ClientConfig>;
    guestlineEmitter: any;
    guestlineEvents: {
      on: (event: string, callback: (...args: any[]) => void) => void;
      off: (event: string, callback: (...args: any[]) => void) => void;
      onRedirectButtonClick: (callback: Function) => void;
      onPanelButtonClick: (callback: Function) => void;
    };
    ga: any;
    gtag: any;
    google_tag_manager: any;
    google_tag_data: any;
    dataLayer: any;
    GLAppInsights: ApplicationInsights;
    dbm: any;
    glDBMTagReady: boolean;
    guestlineQueue: Array<() => void>;
    guestlineReady: (callback: () => void) => void;
  }
}

export type Language = 'en' | 'de' | 'nl' | 'pt' | 'es' | 'it';

const debugEnabled = window.location.search.includes('dbm-debug=');
const CDN_EDITOR_DOMAIN = debugEnabled
  ? (window.ENV_DATA || {}).editorDomain || process.env.EDITOR_DOMAIN
  : (window.ENV_DATA || {}).cdnEditorDomain || process.env.CDN_EDITOR_DOMAIN;

window.guestlineQueue = window.guestlineQueue || [];
window.guestlineReady = callback => {
  if (window.guestlineEvents) {
    callback();
  } else {
    window.guestlineQueue.push(callback);
  }
};

async function tagInit() {
  const tag = document.getElementById('guestline-tag') as HTMLScriptElement | null;

  if (tag === null) {
    window.GLAppInsights.trackTrace({
      message: 'Tag Not Found',
      properties: { page: window.location.href || 'Page info not available' }
    });
    return console.warn(
      'The tag might not be implemented correctly. Please refer to the documentation for more information'
    );
  }

  const triggerEventEmitter = new EventEmitter();

  window.guestlineEvents = {
    on: (event: string, callback: (...args: any[]) => void) => {
      triggerEventEmitter.on(event, callback);
    },
    off: (event: string, callback: (...args: any[]) => void) => {
      triggerEventEmitter.off(event, callback);
    },
    onRedirectButtonClick: (callback: Function) => {
      triggerEventEmitter.on('clicked', event => {
        const element = event.target;
        if (element.hasAttribute('data-guestline-redirect')) {
          callback(event);
        }
      });
    },
    onPanelButtonClick: (callback: Function) => {
      triggerEventEmitter.on('clicked', event => {
        const element = getParentByDataAttribute(
          event.target as Element,
          'data-guestline-show-panel'
        );
        if (element) {
          callback(event);
        }
      });
    }
  };

  const collectionId = tag.getAttribute('data-group-id')!;
  const lng = (tag.getAttribute('data-lng') || window.navigator.language || 'en') as Language;
  const hotelId = tag.getAttribute('data-hotel-id') || undefined;

  const newDomain = (window.ENV_DATA || {}).dbmNewUrl || process.env.DBM_NEW_URL;
  const dbmBaseUrl = `https://${newDomain}/${collectionId.toUpperCase()}`;

  window.GLAppInsights.trackTrace({
    message: 'Tag Initiated',
    properties: { page: window.location.href || 'Page info not available', collectionId }
  });

  try {
    const { integration, analytics, dbm, branding } = await request.get(
      `${CDN_EDITOR_DOMAIN}/api/collections/${collectionId}/configs?sections[]=integration&sections[]=analytics&sections[]=dbm&sections[]=branding`
    );

    window.GLAppInsights.trackTrace({
      message: 'Tag config',
      properties: { integration, collectionId }
    });

    if (window.guestlineEmitter) {
      import('./features/widget').then(({ default: loadWidgetFeature }) => {
        window.guestlineEmitter.on(
          'widget-render',
          (editorIntegrationConfig: IntegrationConfig, collectionId: string) => {
            loadWidgetFeature(editorIntegrationConfig, dbm, collectionId, hotelId);
          }
        );
        window.guestlineEmitter.emit('widget-ready');
      });
    } else {
      triggers({ integration, analytics, lng, hotelId }, dbmBaseUrl, triggerEventEmitter);

      if (integration.panelEnabled) {
        const { default: loadPanelFeature } = await import('./features/panel');

        loadPanelFeature(dbmBaseUrl, collectionId, triggerEventEmitter, {
          branding,
          analytics,
          integration,
          hotelId,
          lng
        });
      } else if (integration.widgetEnabled) {
        const { default: loadWidgetFeature } = await import('./features/widget');
        loadWidgetFeature(integration, dbm, collectionId, hotelId);
      }

      if (integration.basketEnabled) {
        const { default: loadBasketTransferFeature } = await import('./features/basketTransfer');
        loadBasketTransferFeature(dbmBaseUrl, integration, triggerEventEmitter);
      }
    }
  } catch (err) {
    console.error(err);
    window.GLAppInsights.trackException({ exception: err as Error });
  }

  window.guestlineQueue.forEach(callback => callback());
  window.guestlineQueue = [];
}

export default async function main() {
  import('./monitoring')
    .then(async ({ default: monitoringInit }) => {
      monitoringInit();
      tagInit();
    })
    .catch(() => {
      tagInit();
    });
}
